@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    scroll-behavior: smooth;
  }
  body, .carListing {
    @apply !scrollbar-thin !scrollbar-track-transparent !scrollbar-thumb-sunshine;
  }
}
@layer components {
  .logo {
    @apply md:text-2xl text-xl md:font-bold font-semibold tracking-wide transition;
  }
  .cart_head > th {
    @apply !bg-sunshine/10 text-sunshine;
  }
  .shopping_button > button {
    @apply py-3 px-5 border tracking-wider font-medium hover:shadow-md transition duration-500;
  }
  .socials div {
    @apply bg-[#686d71] rounded-full p-2 transition duration-500 ease-in-out hover:shadow hover:text-sunshine cursor-pointer hover:bg-white;
  }
  .clients div {
    @apply shadow-lg rounded-xl bg-white py-8;
    /* box-shadow: 0px 0px 2px 10px #fe3227; */
  }
  .client > div {
    @apply rounded-2xl shadow-xl pt-4 pb-16 px-8 relative;
  }
  .inputMe {
    @apply w-full !border-0 bg-white px-3 py-2 3xl:px-5 3xl:py-3.5 placeholder-gray-500/50 outline-none !rounded-xl focus:ring-0 border-none;
  }
  .tabMe > div {
    @apply border rounded text-center px-4 py-2;
  }
  .models > div {
    @apply rounded-lg shadow-gray-300 shadow-md py-3 cursor-pointer hover:-translate-y-1 transition duration-500 ease-in-out;
  }
  .emoji {
    @apply text-center mx-auto text-6xl text-sunshine my-2;
  }
  .num {
    @apply text-2xl font-semibold tracking-wide;
  }
  .imp {
    text-shadow: 1px 1px 3px #fe3227 !important;
  }
  .impf {
    text-shadow: 0px 0px 1px #fe3227 !important;
  }

  /* remove the inner arrows in the input field with type=number */
  .spin-button-none::-webkit-outer-spin-button, .spin-button-none::-webkit-inner-spin-button {
    appearance: none;
  }
}
