[x-cloak] {
    display: none;
}

@media print {
    .no-printme  {
        display: none;
    }
    .printme  {
        display: block;
    }
    body {
        line-height: 1.2;
    }
}

@page {
    size: A4 portrait;
    counter-increment: page;
}

/* Datepicker */
.date-input {
    background-color: #fff;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    z-index: 2000;
    margin: 3px 0 0 0;
    border-top: 1px solid #eee;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.date-input.is-hidden {
    display: none;
}
.date-input .pika-title {
    padding: 0.5rem;
    width: 100%;
    text-align: center;
}
.date-input .pika-prev,
.date-input .pika-next {
    margin-top: 0;
    /* margin-top: 0.5rem; */
    padding: 0.2rem 0;
    cursor: pointer;
    color: #4299e1;
    text-transform: uppercase;
    font-size: 0.85rem;
}
.date-input .pika-prev:hover,
.date-input .pika-next:hover {
    text-decoration: underline;
}
.date-input .pika-prev {
    float: left;
}
.date-input .pika-next {
    float: right;
}
.date-input .pika-label {
    display: inline-block;
    font-size: 0;
}
.date-input .pika-select-month,
.date-input .pika-select-year {
    display: inline-block;
    border: 1px solid #ddd;
    color: #444;
    background-color: #fff;
    border-radius: 10px;
    font-size: 0.9rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    appearance: none;
}
.date-input .pika-select-month:focus,
.date-input .pika-select-year:focus {
    border-color: #cbd5e0;
    outline: none;
}
.date-input .pika-select-month {
    margin-right: 0.25em;
}
.date-input table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0.2rem;
}
.date-input table th {
    width: 2em;
    height: 2em;
    font-weight: normal;
    color: #718096;
    text-align: center;
}
.date-input table th abbr {
    text-decoration: none;
}
.date-input table td {
    padding: 2px;
}
.date-input table td button {
    /* border: 1px solid #e2e8f0; */
    width: 1.8em;
    height: 1.8em;
    text-align: center;
    color: #555;
    border-radius: 10px;
}
.date-input table td button:hover {
    background-color: #bee3f8;
}
.date-input table td.is-today button {
    background-color: #ebf8ff;
}
.date-input table td.is-selected button {
    background-color: #3182ce;
}
.date-input table td.is-selected button {
    color: white;
}
.date-input table td.is-selected button:hover {
    color: white;
}